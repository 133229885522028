import DefaultLayout from '@/components/Layouts/DefaultLayout';
import { AppPropsWithLayout } from '@/interfaces/common';
import { useApollo } from '@/lib/apolloClient';
import '@/styles/globals.css';
import { ApolloProvider } from '@apollo/client';
// import { Source_Serif_4 } from 'next/font/google';
import localFont from 'next/font/local';

import 'swiper/css';
import 'swiper/css/free-mode';

// export const serifcustom = Source_Serif_4({
//   subsets: ['latin'],
//   display: 'fallback',
//   variable: '--font-serifcustom',
// });

const serifPro = localFont({
  src: [
    {
      path: 'fonts/SourceSerifPro-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: 'fonts/SourceSerifPro-LightItalic.ttf',
      weight: '300',
      style: 'italic',
    },
    {
      path: 'fonts/SourceSerifPro-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: 'fonts/SourceSerifPro-Italic.ttf',
      weight: '400',
      style: 'italic',
    },
    {
      path: 'fonts/SourceSerifPro-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: 'fonts/SourceSerifPro-SemiBoldItalic.ttf',
      weight: '600',
      style: 'italic',
    },
    {
      path: 'fonts/SourceSerifPro-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
    {
      path: 'fonts/SourceSerifPro-BoldItalic.ttf',
      weight: '700',
      style: 'italic',
    },
  ],
  variable: '--font-serifcustom',
});

const geolo = localFont({
  src: [
    {
      path: 'fonts/Geologica_Auto-ExtraLight.ttf',
      weight: '200',
      style: 'normal',
    },
    {
      path: 'fonts/Geologica_Auto-Light.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: 'fonts/Geologica_Auto-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: 'fonts/Geologica_Auto-Medium.ttf',
      weight: '500',
      style: 'normal',
    },
    {
      path: 'fonts/Geologica_Auto-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: 'fonts/Geologica_Auto-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  variable: '--font-geolo',
});

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const Layout = Component.Layout ?? DefaultLayout;
  return (
    <div className={`${geolo.variable} ${serifPro.variable} bg-[#FFF7F0]`}>
      <ApolloProvider client={apolloClient}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ApolloProvider>
    </div>
  );
}
